import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

import { Layout, Menu } from 'antd';
import { SocketContext } from "./SocketContext";

const { Header } = Layout;
const { SubMenu } = Menu;


class MainHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            raspis: [],
            openKeys: [],
            user: null,
            secret: null,
            raspisLoading: true,
            visible: false,
        };
    }
    setupSocketListeners = () => {
        this.props.socket.on("raspis", values => {
            if (JSON.stringify(values) !== JSON.stringify(this.state.raspis)) {
                this.setState({ raspis: values, raspisLoading: false });
            }
        });

        this.props.socket.on("new raspi result", values => {
            if (values.result === "added") {
                setTimeout(() => {
                    this.props.socket.emit("get raspis");
                }, 5000);
            }
        });
    }

    handleVisibleChange = flag => {
        this.setState({ visible: flag });
    };

    componentDidMount() {
        this.props.socket.emit("visit");
        this.props.socket.emit("get raspis");
        this.setupSocketListeners();
        const user = JSON.parse(localStorage.getItem("user"));
        const secret = localStorage.getItem("secret");
        this.setState({ ...this.state, user, secret });
    }

    componentWillUnmount() {
        this.props.socket.removeListener("raspis");
    }

    menuClicked = ({ key, domEvent, ...rest }) => {
        this.props.history.push(key);
    }

    render() {
        const { user } = this.state;
        return <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
            <Link to="/">
                <img src="/favicon-32x32.png" alt="" style={{
                    margin: "16px 24px 16px 0",
                    float: "left"

                }} />
            </Link>
            <div className={'hidden-mobile'}>
                <Link to="/" style={{ color: "white", float: "left", marginRight: "32px" }}>
                    Veebar App
                </Link>
            </div>

            <Menu style={{ lineHeight: '64px' }}
                onClick={this.menuClicked}
                theme={"dark"}
                mode="horizontal"
                triggerSubMenuAction="click"
            >
                <SubMenu
                    key="devices"
                    title={<span className="submenu-title-wrapper"><i className="fas fa-list" /></span>}>
                    <Menu.ItemGroup title="Device List">
                        {this.state.raspis.map(
                            raspi => <Menu.Item key={`/devices/${raspi.id}`}>
                                {raspi.knownName}
                            </Menu.Item>
                        )}
                    </Menu.ItemGroup>
                </SubMenu>
                <SubMenu
                    key="user"
                    title={<span className="submenu-title-wrapper"><i className="fas fa-user" /></span>}>
                    <Menu.ItemGroup title={user?.name || "User"}>
                        {user?.role === 'admin' && <Menu.Item key="/addUser">Add User</Menu.Item>}
                        <Menu.Item key="/logout">Sign Out</Menu.Item>
                    </Menu.ItemGroup>
                </SubMenu>
            </Menu>
        </Header >
    }

}

const WSocket = props => (
    <SocketContext.Consumer>
        {socket => <MainHeader {...props} socket={socket} />}
    </SocketContext.Consumer>
);

export default withRouter(props => <WSocket {...props} />);
