import React, { Component } from 'react';
import moment from 'moment';

class TimeCounter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeElapsed: this.calculateTimeElapsed()
        };
        this.interval = null;
    }

    componentDidMount() {
        this.startTimer();
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }

    componentWillUnmount() {
        this.stopTimer();
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }

    handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            this.updateTimeElapsed();
            this.startTimer();
        } else {
            this.stopTimer();
        }
    }

    startTimer() {
        this.interval = setInterval(this.updateTimeElapsed, 1000);
    }

    stopTimer() {
        clearInterval(this.interval);
    }

    calculateTimeElapsed = () => {
        const now = moment();
        const since = moment(this.props.since);
        const duration = moment.duration(now.diff(since));

        const hours = Math.floor(duration.asHours()).toString().padStart(2, '0');
        const minutes = duration.minutes().toString().padStart(2, '0');
        const seconds = duration.seconds().toString().padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    }

    updateTimeElapsed = () => {
        this.setState({ timeElapsed: this.calculateTimeElapsed() });
    }

    render() {
        return (
            <span>{this.state.timeElapsed}</span>
        );
    }
}

export default TimeCounter;