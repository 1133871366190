import React, { useEffect, useState } from 'react';
import ax from "axios";
import { useParams } from 'react-router-dom';
import { USERS_API_URL } from '../../utils/constants';

import UpdateUser from '../../Components/admin/UpdateUser';
import { Row, Col, Typography, Space } from 'antd';
import DetailTable from '../../Components/admin/DetailTable';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const { Title } = Typography;


const UserDetail = () => {
    const [user, setUser] = useState({});
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const columns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (value, record) => {
                if (record.key === 'sessions') {
                    return `Sessions: ${value.length}`;
                }
                if (record.key === 'visits') {
                    return `Visits: ${value.length}`;
                }
                if (record.key === 'notifications') {
                    return `Notifications: ${value.length}`;
                }

                if (["password", "_id"].includes(record.key)) {
                    return null;
                }
                return value;
            }
        },
    ];

    const deviceColumns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (value, record) => {
                if (record.key === 'knownName') {
                    console.log(record);
                    const { extraProps } = record;
                    return (extraProps?.deviceId ? <Link to={`/devices/${extraProps.deviceId}`}>{value}</Link> : value);
                }

                if (["_id"].includes(record.key)) {
                    return null;
                }
                return value;
            }
        },
    ];


    useEffect(() => {
        let token = localStorage.getItem('token');
        setLoading(true);
        ax.get(`${USERS_API_URL}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => response.data)
            .then(response => {
                let { raspis, ...userDetail } = response;
                setUser(userDetail);
                setDevices(raspis);
            })
            .finally(() => setLoading(false));
    }, [id]);



    return (
        <>
            {user && <div>
                <Title level={1}>{user.name}</Title>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <DetailTable loading={loading} data={user} columns={columns} customKeys={["sessions", "visits", "notifications"]} />
                    </Col>
                    <Col xs={24} md={12}>
                        <Space direction="vertical" style={{ background: '#202020' }}>
                            {user._id && <UpdateUser user={{ name: user.name, email: user.email, phone: user.phone, id: user._id }} />}
                        </Space>
                    </Col>
                </Row>
            </div>}
            <div style={{ marginTop: "4rem" }}>
                {devices && devices.map((device, index) => (
                    <div key={index} style={{ marginBottom: "4rem" }}>
                        <Link to={`/devices/${device._id}`}><Title level={2}>{device.knownName}</Title></Link>
                        <DetailTable data={device} loading={loading} columns={deviceColumns} extraProps={{ deviceId: device._id }} />
                    </div>
                )
                )}
            </div>
        </>
    );
}

export default UserDetail;