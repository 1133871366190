import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { SocketContext } from '../../Components/client/SocketContext';
import { toast } from "react-toastify";
import { Card, Form, Input, Button, Divider } from "antd";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { span: 24 },
};

class DeviceAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingCreate: false
        };
        this.submitForm = this.submitForm.bind(this);
        this.createRaspi = this.createRaspi.bind(this);
    }

    submitForm(values) {

        if (this.state.loading) return;

        this.setState({ loading: true });
        this.props.socket.emit("new raspi", values);

    }

    createRaspi(values) {
        if (this.state.loadingCreate) return;

        this.setState({ loadingCreate: true });
        this.props.socket.emit("create raspi", values);
    }

    componentDidMount() {

        this.props.socket.on("new raspi result", result => {
            this.setState({ loading: false });
            if (result.result === "already paired") {
                toast.error("You already own this device.");
            }

            if (result.result === "not found") {
                toast.error("Device information is incorrect.");
            }

            if (result.result === "added") {
                toast.success("You successfully added this device!");
                this.props.history.push(result?.id ? `/devices/${result.id}/settings` : "/devices");
            }

        });

        this.props.socket.on("create raspi result", result => {
            this.setState({ loadingCreate: false });
            if (result.result === "created") {
                toast.success("You successfully created the room.!");
                this.props.history.push("/devices");
            } else {
                toast.error("Could not generate key!");

            }

        })

    }

    componentWillUnmount() {
        this.props.socket.removeListener('new raspi result');
    }


    render() {
        return <div>
            <div className="container animated fadeIn p-4-no-mobile">
                <br />
                <div className="row justify-content-center ">
                    <div className="col-12 col-md-6">
                        <Card>
                            <h3>
                                Generate a new Secret Key
                            </h3>
                            <Form autoComplete={"off"}
                                {...layout}
                                name="basic"
                                onFinish={this.createRaspi}
                            >
                                <Form.Item
                                    label="Name"
                                    name="knownName"
                                    rules={[{ required: true, message: 'This field is required.' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Serial Number (Optional)"
                                    name="serialNumber"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button type="primary" htmlType="submit">
                                        <i className="fas fa-key" />&nbsp; Generate
                                    </Button>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <Link to={"/"}>
                                        Back
                                    </Link>
                                </Form.Item>
                            </Form>
                            <ul>
                                <li>
                                    Enter <em>Secret Key</em> to setup portal to connect the Veebar device!
                                </li>
                            </ul>

                        </Card>

                        <Divider orientation="left">OR</Divider>

                        <Card>
                            <h3>
                                Use the Existing Secret Key
                            </h3>
                            <Form autoComplete={"off"}
                                {...layout}
                                name="basic"
                                onFinish={this.submitForm}
                            >

                                <Form.Item
                                    label="Secret key"
                                    name="communicationSecret"
                                    rules={[{ required: true, message: 'This field is required.' }]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item {...tailLayout}>
                                    <Button type="primary" htmlType="submit">
                                        <i className="fas fa-plus" />&nbsp; Add
                                    </Button>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <Link to={"/"}>
                                        Back
                                    </Link>
                                </Form.Item>
                            </Form>
                        </Card>

                    </div>
                </div>


            </div>
        </div>;


    }

}


const WSocket = props => (
    <SocketContext.Consumer>
        {socket => <DeviceAdd {...props} socket={socket} />}
    </SocketContext.Consumer>
);


export default WSocket;