import React, { useEffect, useState } from 'react';
import ax from "axios";
import { toast } from "react-toastify";
import { Button, Table, Space, Input } from "antd";
import { CLIENTS_API_URL } from "../../utils/constants";
import { Link } from 'react-router-dom';

const columns = [
    {
        title: 'Known Name',
        dataIndex: 'knownName',
        key: 'knownName',
    },
    {
        title: 'Communication Secret',
        dataIndex: 'communicationSecret',
        key: 'communicationSecret',
    },
    {
        title: 'Icon',
        dataIndex: 'icon',
        key: 'icon',
    },
    {
        title: 'Serial Number',
        dataIndex: 'serialNumber',
        key: 'serialNumber',
    },
    {
        title: 'Pairing Hash',
        dataIndex: 'pairingHash',
        key: 'pairingHash',
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (each) => <ul>{each.map(item => (<li>{item.knownName} - {item.status}</li>))}</ul>
    },
    // {
    //     title: 'Configuration',
    //     dataIndex: 'configuration',
    //     key: 'configuration',
    //     render: (config) => <pre>{JSON.stringify(config, null, 2)}</pre>
    // },
    {
        title: "Actions",
        key: "actions",
        render: (record) => {
            return (
                <Link to={`/devices/${record._id}`}><Button type="primary">Edit</Button></Link>
            );
        }
    }
];

const FiltersAndButtons = ({ onSearch }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
            <Input.Search
                placeholder="Search by name"
                onSearch={onSearch}
                style={{ width: 300 }}
            />
            <Space>
                <Button>Export</Button>
            </Space>
        </div>
    );
};

const Clients = () => {
    const [clients, setClients] = useState([]);
    const [queryParam, setQueryParam] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const token = localStorage.getItem('token');
        setLoading(true);
        ax.get(CLIENTS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: queryParam
        })
            .then(value => value.data)
            .then(value => {
                setClients(value);
            })
            .catch(reason => {
                console.error(reason);
                // toast.error("Failed to fetch clients.");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [queryParam]);
    const handleSearch = (value) => {
        console.log("Search:", value);
        setQueryParam({ search: value });
    };
    return (
        <>
            <FiltersAndButtons onSearch={handleSearch} />
            <Table dataSource={clients} loading={loading} columns={columns} rowKey="_id" />
        </>
    );
};

export default Clients;