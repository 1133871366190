import React, { Component } from 'react';
import ax from "axios";
import { toast } from "react-toastify";
import { Row, Col, Form, Input, Button, Typography } from "antd";
import { ADD_USER_API_URL } from "../../utils/constants";
const { Title } = Typography;

const formLayout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } }

const tailLayout = {
    wrapperCol: { span: 24 },
};

export default class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = { loading: false };
        this.register = this.addUser.bind(this);
    }

    addUser(values) {
        const token = localStorage.getItem('token');
        if (this.state.loading) return;
        this.setState({ loading: true });
        const payload = {
            name: values.name,
            email: values.email,
            password: values.password,
            adminUser: JSON.parse(localStorage.getItem("user")).email,
            adminPass: values.currentPassword
        };
        ax.post(ADD_USER_API_URL, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(value => value.data).then(value => {
            toast.success("User successfully added.");
            this.props.history.goBack();
        }).catch(reason => {
            toast.error("Registration failed.");
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    render() {
        return <div className='p-4-no-mobile' style={{ background: "#202020" }}>
            <Title level={2}>Add New User</Title>
            <Row>
                <Col sm={24} md={24} lg={12}>
                    <Form autoComplete={"off"}
                        {...formLayout}
                        name="addUser"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={this.addUser}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: "Please enter user name." }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            type={"email"}
                            label="Email"
                            name="email"
                            rules={[{ type: 'email', required: true, message: "Please enter user email." }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please enter user password.' }]}>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Current User's Password"
                            name="currentPassword"
                            rules={[{ required: true, message: 'Please enter your current password.' }]}>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <div className="text-center">
                                <Button type="primary" htmlType="submit" disabled={this.state.loading}>
                                    Add User
                                </Button>
                                <Button type="default" onClick={() => this.props.history.goBack()} style={{ marginLeft: '10px' }}>
                                    Go Back
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

        </div >
    }
}