// Client api
export const LOGIN_API_URL = `/api/login`;
export const REGISTER_API_URL = `/api/register`;
export const ADD_USER_API_URL = `/api/add-user`;


// Admin api
export const CLIENTS_API_URL = `/api/devices`;
export const STATUS_API_URL = `/api/status`;
export const USERS_API_URL = `/api/users`;
export const ACTIVITY_API_URL = `/api/activities`;
export const CONSTRAINTS_API_URL = `/api/constraints`;

export const AGGREGATE_ANALYTICS_API_URL = `/api/analytics/aggregate`;
export const ACTIVITY_ANALYTICS_API_URL = `/api/analytics/activity`;

export const TIME_TRIGGERS_API_URL = `/api/time-triggers`;
