import React, { Component } from 'react';

export default class Logout extends Component {

    componentDidMount() {
        localStorage.removeItem("secret");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
    }

    render() {
        return <></>;
    }

}