import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { SocketContext } from '../../Components/client/SocketContext';
import GenericLoader from '../../Components/client/GenericLoader';
import RoomNameEditor from "../../Components/client/RoomNameEditor";
import { Row, Col, Button } from "antd";

class Devices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            raspis: [],
            loading: true
        };
    }


    componentDidMount() {
        this.props.socket.emit("get raspis");

        this.props.socket.on("raspis", values => {
            this.setState({ raspis: values, loading: false });
        });

        this.props.socket.on("raspi icon changed", values => {

            for (let i = 0; i < this.state.raspis.length; i++) {
                if (this.state.raspis[i].id === values.id) {
                    let raspis = this.state.raspis;
                    raspis[i].icon = values.icon;
                    this.setState({ raspis });
                    break;
                }
            }
        });


    }

    componentWillUnmount() {
        this.props.socket.removeListener("raspis");
        this.props.socket.removeListener("raspi icon changed");
    }


    render() {

        if (this.state.loading) return <GenericLoader />;

        let grids = {
            xl: 6,
            lg: 8,
            md: 12,
            sm: 24,
            xs: 24,
            gutter: [16, 16]
        };

        return (
            <div className="animated fadeIn p-4-no-mobile">
                <br />
                <Row gutter={grids.gutter}>
                    {this.state.raspis.map(raspi => <Col xl={grids.xl} lg={grids.lg} md={grids.md} sm={grids.sm}
                        xs={grids.xs} key={raspi.id}>
                        <div className={"room-icon"} style={{
                            backgroundImage: `url(` + require("../../assets/rooms/" + raspi.icon + ".png") + `)`,
                            filter: `hue-rotate(` + (parseInt(`0x${raspi.id}`) % 9) * 40 + `deg)`
                        }}>

                            <div className="content" onClick={e => {

                                if (!raspi.preventClick) {
                                    this.props.history.push("/devices/" + raspi.id);
                                }

                            }}>
                                <RoomNameEditor raspi={raspi}
                                    inChange={v => {
                                        raspi.preventClick = v;
                                    }}
                                    onRename={value => {
                                        this.props.socket.emit("rename raspi", {
                                            id: raspi.id,
                                            newName: value
                                        });
                                    }} onIconChange={value => {
                                        this.props.socket.emit("change raspi icon", { id: raspi.id, newIcon: value });
                                    }}
                                />
                            </div>

                        </div>
                    </Col>)}


                    <Col xl={grids.xl} lg={grids.lg} md={grids.md} sm={grids.sm} xs={grids.xs} gutter={grids.gutter}>
                        <div className={"room-icon"} style={{
                            backgroundImage: `url(` + require("../../assets/rooms/empty.png") + `)`
                        }}>

                            <div className="content">
                                <Link to={"/addDevice/"}>
                                    <i className="fas fa-plus" />{" "}
                                    Add a New Room
                                </Link>
                            </div>

                        </div>
                    </Col>


                </Row>
                <Button key={"report"} style={{ marginTop: '5rem' }} onClick={(e) => { e.preventDefault(); window.open('https://veebartech.ca/report-a-problem', '_blank') }}>
                    <span className={'hidden-mobile'}>Report a &nbsp;</span>Problem...
                </Button>
            </div>
        )
    }

}


const WSocket = props => (
    <SocketContext.Consumer>
        {socket => <Devices {...props} socket={socket} />}
    </SocketContext.Consumer>
);


export default WSocket;