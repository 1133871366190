import React, { useState } from 'react';
import ax from "axios";
import { toast } from "react-toastify";
import { Col, Row, Form, Input, Button, Card } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { REGISTER_API_URL } from '../utils/constants';

const { Item } = Form;

const Register = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const register = values => {
        if (values.password !== values.confirmPassword) {
            toast.error("Passwords do not match.");
            return;
        }
        if (loading) return;
        setLoading(true);
        const payload = {
            name: values.name,
            email: values.email,
            password: values.password
        }
        ax.post(REGISTER_API_URL, payload)
            .then(value => value.data)
            .then(value => {
                toast.success("Registration successful. Login to continue.");
                props.history.push("/login");
            })
            .catch(reason => {
                toast.error("Registration failed.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Row justify="center" align="middle" className='min-h-screen-no-mobile'>
            <Col md={24} lg={12} xl={8}>
                <Card style={{ padding: "2rem" }}>
                    <div style={{ marginBottom: "4rem" }} className="text-center">
                        <img style={{ width: "8rem", height: '8rem' }} src={"/android-chrome-192x192.png"} alt={"Veebar Home"} />
                        <h3>Register Veebar Home Automation</h3>
                    </div>
                    <Form
                        form={form}
                        layout="vertical"
                        autoComplete="off"
                        requiredMark="optional"
                        name="register"
                        initialValues={{ remember: true }}
                        onFinish={register}>
                        <Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input your name.' }]}>
                            <Input prefix={<UserOutlined />} placeholder="Name" />
                        </Item>
                        <Item
                            label="Email"
                            name="email"
                            rules={[{ type: 'email', required: true, message: 'Please input a valid email.' }]}>
                            <Input prefix={<UserOutlined />} placeholder="Email" />
                        </Item>

                        <Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password.' }]}>
                            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                        </Item>

                        <Item
                            label="Confirm Password"
                            name="confirmPassword"
                            rules={[{ required: true, message: 'Please confirm your password.' }]}>
                            <Input.Password prefix={<LockOutlined />} placeholder="Confirm Password" />
                        </Item>

                        <Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Register
                            </Button>
                        </Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default Register;