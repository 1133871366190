import React, { Component } from "react";
import { Spin } from 'antd';

export default class GenericLoader extends Component {
    render() {
        return (
            <Spin size='large' tip="We are preparing the content for you." />
        )
    }
}

