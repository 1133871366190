import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { SocketContext } from '../../Components/client/SocketContext';
import { toast } from "react-toastify";
import GenericLoader from "../../Components/client/GenericLoader";
import { Row, Col, Card, Button, Input } from "antd";


class DeviceSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            raspi: {},
            raspiLoading: true,
            config: {},
            inReverse: false
        };
        this.save = this.save.bind(this);
    }

    save() {
        this.props.socket.emit("raspi configuration", { raspi: this.state.raspi, configuration: this.state.config });
    }

    componentDidMount() {
        this.props.socket.emit("get raspi", this.props.match.params.id);
        this.props.socket.on("raspi:" + this.props.match.params.id, value => {
            this.setState({ raspi: value, raspiLoading: false, config: value?.configuration || {} });
        });

        this.props.socket.on("raspi configuration saved", () => {
            this.props.history.push("/devices/" + this.props.match.params.id);
            toast.success("Settings changed successfully.");

        });
    }

    componentWillUnmount() {
        this.props.socket.removeListener("raspi configuration saved");
        this.props.socket.removeListener("raspi:" + this.props.match.params.id);
    }

    copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.success("Copied to clipboard");
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    render() {

        if (this.state.raspiLoading) return <GenericLoader />;

        return <div className='p-4-no-mobile'>
            <div className="animated fadeIn">
                <h2>
                    {this.state.raspi.knownName} Settings
                </h2>
                <hr style={{ marginBottom: "2rem" }}></hr>
                <Row gutter={[32, 32]}>
                    {Object.keys(this.state.config).map(value => {
                        if (value.endsWith("threshold")) return null;
                        return (<Col xs={24} sm={12} md={12} lg={6} xl={4} key={value}>

                            {/* {value.endsWith("threshold") && <Fragment>
                            Stove {value.replace("stove", "").replace("threshold", "")} sensitivity:

                            <Slider min={1} max={60} marks={{ 1: "Low", 29: "Medium", 60: "High" }} onChange={e => {
                                let config = { ...this.state.config };
                                config[value] = this.inReverse ? 61 - e : e;
                                this.setState({ config });
                            }} defaultValue={this.inReverse ? 61 - this.state.config[value] : this.state.config[value]} disabled={false} />
                        </Fragment>} */}


                            {value.endsWith("triggerdelay") && <Fragment>
                                <span>Stove {value.replace("stove", "").replace("triggerdelay", "")} alert delay</span>
                                <Input
                                    label="Alert Delay"
                                    addonAfter="Minutes"
                                    onChange={e => {
                                        let inputValue = e.target.value;
                                        if (e.target.value === "") inputValue = 0;
                                        let config = { ...this.state.config };
                                        config[value] = parseInt(inputValue) * 60;
                                        this.setState({ config });
                                    }} value={this.state.config[value] / 60} />
                            </Fragment>}
                        </Col>)
                    }
                    )}
                </Row>

                <div style={{ "margin": "2rem 0" }}>
                    <Button style={{ marginRight: "1rem" }} type="primary" htmlType="submit" onClick={this.save}>
                        Save
                    </Button>
                    <Link to={"/devices/" + this.props.match.params.id}>Back</Link>
                </div>

                <Card loading={false}>
                    <h3>
                        Device Information
                    </h3>
                    Serial Number: {this.state.raspi.serialNumber}<br />
                    Secret Key: {this.state.raspi.communicationSecret}&nbsp;{window.isSecureContext && <Button onClick={() => this.copyToClipboard(this.state.raspi.communicationSecret)}>Copy</Button>}<br />
                    {/* Pairing Secret: {this.state.raspi.pairingHash}<br /> */}
                    Firmware version: 1.0.1<br />
                </Card>

            </div>
            <br />
            <br />
        </div>;
    }
}


const WSocket = props => (
    <SocketContext.Consumer>
        {socket => <DeviceSettings {...props} socket={socket} />}
    </SocketContext.Consumer>
);


export default WSocket;
