import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {toast} from "react-toastify";

class RoomNameEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {edit: false, value: this.props.raspi.knownName, icon: this.props.raspi.icon};
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        // alert(JSON.stringify(this.props));
    }

    submit(e) {
        if (e) e.preventDefault();

        if (this.state.value === "") {
            toast.error("Please enter a name for your room.");
            return;
        }
        this.props.onRename(this.state.value);
        this.props.onIconChange(this.state.icon);
        this.setState({
            edit: false
        });
    }

    render() {
        return <div>
            <div style={{display: "flex", justifyContent: "space-between"}}>

                <div className="left">
                    {this.state.edit && <form onSubmit={this.submit}>
                        <input type="text" value={this.state.value} style={{width: "200px"}} onChange={
                            e => {
                                this.setState({value: e.target.value});
                            }
                        } autoFocus/>
                    </form>}

                    {!this.state.edit &&
                    <Link to={"/devices/" + this.props.raspi.id} className={"float-left"}>
                        {this.state.value}
                    </Link>}
                </div>

                <div className="right" style={{margin: "0 5px", cursor: "pointer"}} onClick={e => {
                    e.stopPropagation();
                }}>
                    {!this.state.edit && <i onClick={() => {
                        let edit = !this.state.edit;
                        this.setState({edit});
                        this.props.inChange(edit);
                    }} className="fas fa-pencil-alt"/>}
                    {this.state.edit && <i onClick={() => {
                        this.submit();
                        this.props.inChange(false);
                    }} className="fas fa-check"/>}
                </div>

            </div>

            {this.state.edit && <div>
                <label htmlFor="" style={{fontSize: "1rem"}}>
                    Default Image
                </label>
                <select className="form-control" style={{
                    color: "black",
                    width: "100%"
                }}
                        value={this.state.icon} onChange={e => {
                    this.setState({icon: e.target.value});
                }}>
                    <option value="empty">Default Room</option>
                    <option value="livingRoom">Living Room</option>
                    <option value="bedRoom">Bedroom</option>
                </select>
            </div>}


        </div>;
    }
}

export default withRouter(props => <RoomNameEditor {...props}/>);