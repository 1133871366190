import React from 'react';
import { Table } from 'antd';

const defaultColumns = [
    {
        title: 'Key',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
    }
]

const DetailTable = ({ data, columns = defaultColumns, customKeys = [], loading = false, extraProps }) => {
    const renderTableData = (data) => {
        return Object.entries(data).map(([key, value]) => {
            if (customKeys.includes(key)) {
                return {
                    key: key,
                    value: value,
                    extraProps: { ...extraProps },
                };
            }
            if (typeof value === 'object' && !Array.isArray(value)) {
                return {
                    key: key,
                    value: (
                        <Table
                            columns={columns}
                            dataSource={renderTableData(value)}
                            pagination={false}
                            loading={loading}
                            bordered
                        />
                    ),
                    extraProps: { ...extraProps },
                };
            } else if (Array.isArray(value)) {
                return {
                    key: key,
                    value: (
                        <Table
                            columns={columns}
                            dataSource={value.map((item, index) => ({
                                key: index + 1,
                                value: (
                                    <Table
                                        columns={columns}
                                        dataSource={renderTableData(item)}
                                        pagination={false}
                                        loading={loading}
                                        bordered
                                    />
                                ),
                            }))}
                            pagination={false}
                            bordered
                        />
                    ),
                    extraProps: { ...extraProps },
                };
            } else {
                return {
                    key: key,
                    value: value.toString(),
                    extraProps: { ...extraProps },
                };
            }
        });
    };

    return (
        <Table
            columns={columns}
            dataSource={renderTableData(data)}
            pagination={false}
            loading={loading}
            bordered
        />
    );
};

export default DetailTable;