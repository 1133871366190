import React, { useState, useEffect } from "react";
import { Menu, Layout } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';

import {
    UserOutlined,
    HomeOutlined,
    DeploymentUnitOutlined,
    MonitorOutlined
} from '@ant-design/icons';

const { Sider } = Layout;

const AppHeader = () => {
    const { pathname } = useLocation();

    const menuItems = [
        { key: 'dashboard', icon: <HomeOutlined />, label: 'Dashboard', path: '/dashboard' },
        { key: 'devices', icon: <DeploymentUnitOutlined />, label: 'Devices', path: '/devices' },
        { key: 'users', icon: <UserOutlined />, label: 'Users', path: '/users' },
        { key: 'activities', icon: <MonitorOutlined />, label: 'Activities', path: '/activities' }
    ];
    const key = menuItems.find(item => pathname.includes(item.key))?.key || 'dashboard';
    const [selectedMenu, setSelectedMenu] = useState(key);

    useEffect(() => {
        const key = menuItems.find(item => pathname.includes(item.key))?.key || 'dashboard';
        setSelectedMenu(key);
    }, [pathname]);


    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
        >
            <NavLink to='/' className="logo">
                <img src="/vlogo.png" />
            </NavLink>
            <Menu theme="dark" defaultSelectedKeys={[selectedMenu]} mode="inline">
                {menuItems.map(item => (
                    <Menu.Item key={item.key} icon={item.icon}>
                        <NavLink to={item.path} className="nav-text">{item.label}</NavLink>
                    </Menu.Item>
                ))}
            </Menu>
        </Sider>
    )
}

export default AppHeader;