import React from 'react';
import "antd/dist/antd.dark.min.css";
import "./Pages/admin/Styles.css";
import {
    SettingTwoTone,
    UserOutlined,
    LogoutOutlined,
    UserAddOutlined,
    ControlOutlined,
    DownOutlined
} from '@ant-design/icons';
import { toast, ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Layout, Button, Space, Dropdown } from "antd";

import ProtectedRoute from './Components/ProtectedRoute';

import Clients from './Pages/admin/Clients';
import ClientDetail from './Pages/admin/ClientDetail';
import Dashboard from './Pages/admin/Dashboard';
import Activities from './Pages/admin/Activities';
import ActivityDetail from './Pages/admin/ActivityDetail';
import Users from './Pages/admin/Users';
import UserDetail from './Pages/admin/UserDetail';
import AddUser from './Pages/admin/AddUser';
import UpdateSensorConstraints from './Pages/admin/UpdateSensorConstraints';
import Login from './Pages/Login';
import Logout from './Pages/Logout';

import AppHeader from './Components/admin/Header';



const { Header, Content } = Layout;


const App = () => {

    const handleMenuClick = (e) => {
        if (e.key === '/logout') {
            localStorage.clear();
            window.location.href = '/login';
        }
    };
    const user = JSON.parse(localStorage.getItem("user"));
    const dropDownMenuItems = [
        {
            label: <Link to='/addUser'>Add User</Link>,
            key: '/addUser',
            icon: <UserAddOutlined />,
            disabled: user?.role !== 'admin',
        },
        {
            label: <Link to='/updateConstraints'>Sensor Constraints</Link>,
            key: '/updateConstraints',
            icon: <ControlOutlined />,
            disabled: user?.role !== 'admin',
        },
        {
            label: 'Sign Out',
            key: '/logout',
            icon: <LogoutOutlined />,
        },
    ];

    const dropdownMenuProps = {
        items: dropDownMenuItems,
        onClick: handleMenuClick,
    };
    return (
        <Router>
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
            <Route exact path="/login" component={Login} />
            <Layout style={{ minHeight: '100vh' }}>
                <AppHeader />
                <Layout>
                    <Header className="site-layout-sub-header-background">
                        <Dropdown menu={dropdownMenuProps} trigger={['click']}>
                            <Button style={{ margin: "1rem" }}>
                                <Space>
                                    <SettingTwoTone /> {user?.name || "Veebar Admin"}
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </Header>
                    <Content>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 'calc(100vh - 64px)' }}>
                            <ProtectedRoute path="/dashboard" exact component={Dashboard} />
                            <ProtectedRoute path="/devices" exact component={Clients} />
                            <ProtectedRoute path="/devices/:id" exact component={ClientDetail} />
                            <ProtectedRoute path="/users" exact component={Users} />
                            <ProtectedRoute path="/users/:id" exact component={UserDetail} />
                            <ProtectedRoute path="/addUser" exact component={AddUser} />
                            <ProtectedRoute path="/activities" exact component={Activities} />
                            <ProtectedRoute path="/activities/:id" exact component={ActivityDetail} />
                            <ProtectedRoute path="/updateConstraints" exact component={UpdateSensorConstraints} />
                            <ProtectedRoute exact path="/logout" component={Logout} />
                            <ProtectedRoute path="/" exact component={() => {
                                return <Redirect to={"/dashboard"} />
                            }
                            } />
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </Router>
    );
}

export default App;