import React, { useEffect, useState } from 'react';
import ax from "axios";
import { toast } from "react-toastify";
import { Col, Row, Form, Input, Button, Divider, Card } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { LOGIN_API_URL } from '../utils/constants';

const { Item } = Form;

const Login = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const login = values => {
        if (loading) return;
        setLoading(true);
        ax.post(LOGIN_API_URL, values, { withCredentials: true })
            .then(value => value.data)
            .then(value => {
                localStorage.setItem("secret", value.secret);
                localStorage.setObject("user", value.user);
                localStorage.setItem("token", value.token);
                window.location.href = "/";
            })
            .catch(reason => {
                toast.error("Authentication failed.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        let token = localStorage.getItem("token")
        let user = localStorage.getObject("user")
        if (token && user === "admin") {
            props.history.push("/clients");
        }
        if (token) {
            props.history.push("/devices");
        }
    }, [props.history]);

    return (
        <Row justify="center" align="middle" className='min-h-screen-no-mobile'>
            <Col md={24} lg={12} xl={8} className="max-width-mobile">
                <Card>
                    <div className="text-center" style={{ marginBottom: "2rem" }}>
                        <img src={"/android-chrome-192x192.png"} alt={"Veebar Home"} />
                        <h3>Veebar Home Automation</h3>
                    </div>

                    <Form
                        form={form}
                        name="login"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        requiredMark="optional"
                        autoComplete="off"
                        onFinish={login}>
                        <Item
                            name="email"
                            label="Email"
                            rules={[{ type: 'email', required: true, message: 'Please input your email.' }]}>
                            <Input prefix={<UserOutlined />} placeholder="Email" />
                        </Item>

                        <Item
                            name="password"
                            label="Password"
                            rules={[{ required: true, message: 'Please input your password.' }]}>
                            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                        </Item>

                        <Item>
                            <a href='mailto:info@veebartech.ca'>Forgot password</a>
                            <Button type="primary" htmlType="submit" loading={loading} block>
                                Login
                            </Button>
                        </Item>
                        <Divider plain>New to Veebar</Divider>
                        <Item style={{ textAlign: "center" }}>
                            <Link className='ant-btn ant-btn-secondary' to='/register'>
                                Register
                            </Link>
                        </Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default Login;