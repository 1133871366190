import React, { useState, useEffect } from 'react';
import { Card, Statistic, Select } from 'antd';
import { Link } from "react-router-dom";
import { ArrowUpOutlined } from '@ant-design/icons';
import { ACTIVITY_ANALYTICS_API_URL } from "../../utils/constants";
import ax from "axios";

const { Option } = Select;

const ActivityStaticsCard = ({ activityCount }) => {
    const [count, setCount] = useState(activityCount?.count);
    const [timePeriod, setTimePeriod] = useState('24h');

    const handleTimePeriodChange = (value) => {
        setTimePeriod(value);
    };

    const timePeriodText = {
        '24h': 'Last 24 hours',
        '7d': 'Last 7 days',
        '30d': 'Last 30 days',
    };

    useEffect(() => {
        const fetchActivityCount = async () => {
            let now = new Date();
            let startDate;
            if (timePeriod === '24h') {
                startDate = new Date(now - 24 * 60 * 60 * 1000);
            }
            if (timePeriod === '7d') {
                startDate = new Date(now - 7 * 24 * 60 * 60 * 1000);
            }
            if (timePeriod === '30d') {
                startDate = new Date(now - 30 * 24 * 60 * 60 * 1000);
            }
            try {
                const response = await ax.get(`${ACTIVITY_ANALYTICS_API_URL}`, {
                    params: {
                        from: startDate.toISOString()
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                console.log("Activity count response:", response);
                setCount(response.data.count);
            } catch (error) {
                console.error("Failed to fetch activity count:", error);
            }
        };

        fetchActivityCount();
    }, [timePeriod]);

    return (
        <Card className="dashboard-card">

            <Statistic
                title={<Link style={{ color: "inherit" }} to="/activities">{"Total Activities"}</Link>}
                value={count}
                valueStyle={{ color: '#267ddc' }}
                suffix={<Select
                    defaultValue="24h"
                    style={{ minWidth: 150, marginLeft: 16 }}
                    onChange={handleTimePeriodChange}
                    onClick={(event) => event.stopPropagation()}

                >
                    <Option value="24h">Last 24 hours</Option>
                    <Option value="7d">Last 7 days</Option>
                    <Option value="30d">Last 30 days</Option>
                </Select>}
                prefix={<Link style={{ color: "inherit" }} to="/activities"><ArrowUpOutlined /></Link>}
            />

        </Card >
    );
};

export default ActivityStaticsCard;