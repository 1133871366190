import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { SocketContext } from '../../Components/client/SocketContext';
import TimeCounter from "../../Components/client/TimeCounter";
import GenericLoader from "../../Components/client/GenericLoader";
import { Button, Row, Col, Tooltip } from "antd";

class Device extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            raspi: {},
            status: [],
            raspiLoading: true,
            statusLoading: true,
            smokeAlarms: 0,
            stoves: 0
        };
    }

    editName(id, oldName) {
        let va = prompt("Please enter a new name for " + oldName + " or cancel.", oldName);
        if (va)
            this.props.socket.emit("rename sensor", { id: id, newName: va });
    }

    componentDidMount() {

        this.props.socket.emit("get raspi", this.props.match.params.id);

        this.props.socket.on("sensor renamed", () => {
            this.props.socket.emit("get raspi status", this.props.match.params.id);
        });

        this.props.socket.on("raspi:" + this.props.match.params.id, value => {

            this.setState({ raspi: value, raspiLoading: false });

            this.props.socket.emit("get raspi status", this.props.match.params.id);

            this.props.socket.on("raspi status:" + this.props.match.params.id, value => {

                this.setState({ status: value, statusLoading: false });
            });


        });


    }

    componentWillUnmount() {
        this.props.socket.removeListener("raspi:" + this.props.match.params.id);
        this.props.socket.removeListener("raspi status:" + this.props.match.params.id);
        this.props.socket.removeListener("sensor renamed");
    }

    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    render() {
        if (this.state.raspiLoading) return <GenericLoader />;

        let grids = {
            xl: 6,
            lg: 12,
            md: 6,
            sm: 8,
            xs: 12,
            gutter: [16, 16]
        };

        return <div className='p-4-no-mobile'>


            <div className="container animated fadeIn">

                <Row gutter={[16, 16]}>
                    <Col md={18} sm={12}>
                        <h2>
                            {this.state.raspi.knownName}
                        </h2>
                    </Col>
                    <Col md={6} style={{ textAlign: "right" }} sm={12}>
                        <h2>
                            <Tooltip title={"Edit Sensor Names"}>
                                <Button type={"link"} style={{ fontSize: "21px" }} onClick={() => {
                                    let editMode = !this.state.editMode;
                                    this.setState({ editMode });
                                }}>
                                    <i className="fas fa-pencil-alt" />
                                </Button>
                            </Tooltip>
                            &nbsp;
                            &nbsp;
                            <Link to={"/devices/" + this.props.match.params.id + "/settings"}>
                                <Tooltip title={"Settings"}>
                                    <i className="fas fa-cog" />
                                </Tooltip>
                            </Link>
                        </h2>
                    </Col>
                </Row>


                {!this.state.statusLoading && <Fragment>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <h3>
                                Smoke Alarms
                            </h3>
                            <hr />
                            <br />
                            <Row gutter={grids.gutter}>
                                {this.state.status.map(k => k.sensorType === "fireAlarm" &&

                                    <Col xl={grids.xl} lg={grids.lg} md={grids.md} sm={grids.sm} xs={grids.xs}
                                        key={k._id} className={"sq"}>

                                        <div className="content">
                                            <div className="text-center">

                                                <img src={"/fire.png"} alt={k.knownName}
                                                    className={"fire " + (k.status === "OFF" ? "bw" : "")} />
                                                <br />
                                                <span className='span-color-white'>
                                                    {k.knownName}&nbsp;{this.state.editMode &&
                                                        <i className="fas fa-pencil-alt" onClick={() => {
                                                            this.editName(k._id, k.knownName);
                                                        }} />}
                                                </span><br />
                                                {k.status === "ON" && <span className="span-color-white">
                                                    On for: {<TimeCounter since={k.updatedAt} />}
                                                </span>}

                                                {k.status === "OFF" && <span className="span-color-white">
                                                    Off
                                                </span>}
                                            </div>
                                        </div>


                                    </Col>)}
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <h3>
                                Stoves
                            </h3>
                            <hr />
                            <br />
                            <Row gutter={grids.gutter}>
                                {this.state.status.map(k => k.sensorType === "stove" &&
                                    <Col xl={grids.xl} lg={grids.lg} md={grids.md} sm={grids.sm} xs={grids.xs}
                                        key={k._id} className={"sq"}>

                                        <div className="content">
                                            <div className="text-center">
                                                <img src={"/stove-on.png"} alt={k.knownName}
                                                    className={"stove " + (k.status === "OFF" ? "bw" : "")} />
                                                <br />
                                                <span
                                                    className="span-color-white">{k.knownName}
                                                    &nbsp;
                                                    {this.state.editMode &&
                                                        <i className="fas fa-pencil-alt" onClick={() => {
                                                            this.editName(k._id, k.knownName);
                                                        }} />}</span>
                                                <br />
                                                {k.status === "ON" && <span className="span-color-white">
                                                    On for: {<TimeCounter since={k.updatedAt} />}
                                                </span>}

                                                {k.status === "OFF" && <span className="span-color-white">
                                                    Off
                                                </span>}


                                            </div>
                                        </div>

                                    </Col>)}
                            </Row>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <h3>
                                Alarms
                            </h3>
                            <hr />
                            <br />
                            <Row gutter={grids.gutter}>
                                {this.state.status.map(k => k.sensorType === "alarm" &&
                                    <Col xl={grids.xl} lg={grids.lg} md={grids.md} sm={grids.sm} xs={grids.xs}
                                        key={k._id} className={"sq"}>

                                        <div className="content">
                                            <div className="text-center">
                                                <img src={"/alarm.png"} alt={k.knownName}
                                                    className={"stove " + (k.status === "OFF" ? "bw" : "")} />
                                                <br />
                                                <span
                                                    className="span-color-white">{k.knownName}
                                                    &nbsp;
                                                    {this.state.editMode &&
                                                        <i className="fas fa-pencil-alt" onClick={() => {
                                                            this.editName(k._id, k.knownName);
                                                        }} />}</span>
                                                <br />
                                                {k.status === "ON" && <span className="span-color-white">
                                                    On for: {<TimeCounter since={k.updatedAt} />}
                                                </span>}

                                                {k.status === "OFF" && <span className="span-color-white">
                                                    Off
                                                </span>}


                                            </div>
                                        </div>

                                    </Col>)}
                            </Row>
                        </Col>

                    </Row>


                </Fragment>
                }

                {this.state.statusLoading && <GenericLoader />}


            </div >
        </div >;


    }

}


const WSocket = props => (
    <SocketContext.Consumer>
        {socket => <Device {...props} socket={socket} />}
    </SocketContext.Consumer>
);


export default WSocket;