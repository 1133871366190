import React, { useEffect, useState } from 'react';
import ax from "axios";
import { useParams } from 'react-router-dom';
import { Typography, Space, Row, Col, Switch } from 'antd';

import DetailTable from '../../Components/admin/DetailTable';
import { CLIENTS_API_URL, STATUS_API_URL } from '../../utils/constants';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';


const { Title } = Typography;

const ToggleStatus = ({ text, record }) => {
    const [status, setStatus] = useState(text);
    const [loading, setLoading] = useState(false);
    const handleToggle = (checked) => {
        const { extraProps } = record;
        setLoading(true);
        let token = localStorage.getItem('token');
        const payload = { statusId: extraProps.sensorId, raspi: extraProps.raspi, newStatus: checked ? 'ON' : 'OFF' }
        ax.put(STATUS_API_URL, payload, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.status === 200) {
                    setStatus(checked ? 'ON' : 'OFF');
                }
            })
            .finally(() => setLoading(false));
    }
    return <Switch
        checkedChildren="ON"
        unCheckedChildren="OFF"
        checked={status.toUpperCase() === 'ON'}
        onChange={handleToggle}
        loading={loading}
    />
};


const ClientDetail = () => {
    const [user, setUser] = useState({});
    const [client, setClient] = useState({});
    const [sensors, setSensors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [configuration, setConfiguration] = useState({});
    const { id } = useParams();

    const columns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (text, record) => {
                if (record.key === 'status') {
                    return (
                        <ToggleStatus
                            text={text}
                            record={record}
                        />
                    );
                }
                return text;
            },
        },
    ];


    useEffect(() => {
        const token = localStorage.getItem('token');
        setLoading(true);
        ax.get(CLIENTS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`
            }, params: { raspi: id }
        })
            .then(value => value.data)
            .then(value => {
                if (value.length === 0) {
                    return;
                }
                let _client = value[0];
                let { status, configuration, ...rest } = _client;
                setClient(rest);
                setSensors(status);
                setConfiguration(configuration);
            })
            .catch(reason => {
                console.error(reason);
            })
            .finally(() => { setLoading(false) });
    }, [id]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        setLoading(true);
        ax.get(`${CLIENTS_API_URL}/${id}/user`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(value => value.data)
            .then(value => {
                setUser(value);
            })
            .catch(reason => {
                console.error(reason);
            })
            .finally(() => { setLoading(false) });
    }, [id]);
    return (
        <>
            <Row gutter={[16, 16]}>
                {client && <Col span={12} style={{ marginBottom: "4rem" }}>
                    <Title level={1}>{client.knownName}</Title>
                    <DetailTable data={client} />
                </Col >}
                {user && <Col span={12} style={{ marginBottom: "4rem" }}>
                    <Link to={`/users/${user._id}`}><Title level={1}><><UserOutlined style={{ marginRight: 5 }} />{user.name}</></Title></Link>
                    <DetailTable data={user} />
                </Col >}
            </Row>
            {configuration && <div style={{ marginBottom: "4rem" }}>
                <Title level={4}>Configuration</Title>
                <DetailTable data={configuration} />
            </div >}
            <Space direction="vertical" size="large">
                {sensors && sensors.length > 0 && <Title level={2}>Sensors</Title>}
                <Row gutter={[16, 16]}>
                    {sensors && sensors.map((sensor, index) => {
                        return (
                            <Col span={12} key={index}>
                                <Title level={4}>{sensor.knownName}</Title>
                                <DetailTable data={sensor} columns={columns} extraProps={{ sensorId: sensor._id, raspi: id }} />
                            </Col>
                        );
                    })}
                </Row>
            </Space>

        </>

    )
}

export default ClientDetail;