import React, { useEffect, useState } from "react";
import { Table, DatePicker, Space, Form, Button } from "antd";
import ax from "axios";
import { ACTIVITY_API_URL } from "../../utils/constants";
import { Link } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker;

const Activities = () => {
    const token = localStorage.getItem('token');
    const now = new Date();
    const last30Days = new Date(now - 30 * 24 * 60 * 60 * 1000);

    const [queryParam, setQueryParam] = useState({ start: last30Days });
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchActivities = async () => {
            setLoading(true);
            try {
                console.log("Fetching activities with query:", queryParam);
                const response = await ax.get(ACTIVITY_API_URL, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: queryParam
                });
                setActivities(response.data);
            } catch (error) {
                console.error("Failed to fetch activities:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchActivities();
    }, [queryParam, token]);

    const handleTableChange = (pagination, filters) => {
        const now = new Date();
        let startDate;

        if (filters.createdAt) {
            const filterValue = filters.createdAt[0];
            if (filterValue === '24h') {
                startDate = new Date(now - 24 * 60 * 60 * 1000);
            } else if (filterValue === '7d') {
                startDate = new Date(now - 7 * 24 * 60 * 60 * 1000);
            } else if (filterValue === '30d') {
                startDate = new Date(now - 30 * 24 * 60 * 60 * 1000);
            }
        } else {
            startDate = last30Days;
        }

        setQueryParam({ start: startDate });
    };

    const handleDateChange = (dates, dateStrings) => {
        if (dates) {
            setQueryParam({ start: dates[0].toDate(), end: dates[1].toDate() });
        } else {
            setQueryParam({ start: last30Days });
        }
    };

    return (
        <>
            <Space style={{ marginBottom: 16 }}>
                <Form.Item label="Filter by Date">
                    <RangePicker
                        defaultValue={[moment(last30Days), moment(now)]}
                        onChange={handleDateChange}
                    />
                </Form.Item>
            </Space>
            <Table
                dataSource={activities}
                loading={loading}
                columns={[
                    {
                        title: 'Device',
                        dataIndex: 'raspi',
                        key: 'raspi',
                        render: (raspi) => (
                            <Link to={`/devices/${raspi._id}`}>
                                {raspi.knownName}
                            </Link>
                        )
                    },
                    {
                        title: 'Communication Secret',
                        dataIndex: 'raspi',
                        key: 'raspi',
                        render: (raspi) => (
                            <>{raspi.communicationSecret}</>
                        )
                    },
                    {
                        title: 'Payload',
                        dataIndex: 'payload',
                        key: 'payload',
                        sorter: (a, b) => a.value - b.value, // TODO: Fix sorting
                        render: (payload) => (
                            <>{payload.sensorType} {payload.sensorID} - {payload.value}</>
                        )
                    },
                    {
                        title: 'Time',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: (time) => new Date(time).toLocaleString(),
                        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
                        defaultFilteredValue: ['30d']
                    },
                    {
                        title: "Action",
                        key: "action",
                        render: (text, record) => {
                            console.log("Record:", record, text)
                            return (
                                <Space size="middle" >
                                    <Link to={`/activities/${record._id}`}><Button type="primary">View</Button></Link>
                                </Space >
                            )
                        }
                    }
                ]}
                rowKey="_id"
                onChange={handleTableChange}
            />
        </>
    );
};

export default Activities;
