import React, { useEffect, useState } from "react";
import { Typography, Space, Row, Col, Switch, Card } from 'antd';
import DetailTable from '../../Components/admin/DetailTable';
import { ACTIVITY_API_URL } from "../../utils/constants";
import { useParams } from 'react-router-dom';
import ax from "axios";
import { Link } from "react-router-dom";

const { Title } = Typography;



const ActivityDetail = () => {
    const [activity, setActivity] = useState({});
    const [users, setUsers] = useState([]);
    const [device, setDevice] = useState({});
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        const token = localStorage.getItem('token');
        setLoading(true);
        ax.get(`${ACTIVITY_API_URL}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(value => value.data)
            .then(value => {
                let { users, raspi, ...activityDetail } = value;
                setActivity(activityDetail);
                setDevice(raspi);
                setUsers(users);
            })
            .catch(reason => {
                console.error(reason);
                // toast.error("Failed to fetch activities.");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);
    return (
        <>
            {activity && (
                <>
                    <Title level={3}>Activity Detail</Title>
                    <DetailTable loading={loading} data={activity} />
                </>
            )}
            {users && (
                <>
                    <Title style={{ marginTop: 20 }} level={3}>Associated Users</Title>
                    <Row gutter={[16, 16]}>
                        {users.map((user, index) => (
                            <Col key={index} xs={24} sm={12} md={8} lg={6}>
                                <Link to={`/users/${user._id}`}>
                                    <Card title={user.name} style={{ marginBottom: 20 }}>
                                        {user.email} {user.phone ? `- ${user.phone}` : ''}
                                    </Card>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </>
            )}
            {device && (
                <>
                    <Title style={{ marginTop: 20 }} level={3}>Device Detail</Title>
                    <DetailTable loading={loading} data={device} />
                </>
            )}
        </>
    );
}

export default ActivityDetail;