import React, { useEffect } from "react";
import ax from "axios";
import { toast } from "react-toastify";
import { Row, Col, Form, Input, Button, Typography } from "antd";
import { CONSTRAINTS_API_URL } from "../../utils/constants";

const { Title } = Typography;

const formLayout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } }

const tailLayout = {
    wrapperCol: { span: 24 },
};

const UpdateSensorConstraints = () => {
    const [loading, setLoading] = React.useState(false);
    const [allowedDevicePrefixes, setAllowedDevicePrefixes] = React.useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        setLoading(true);
        ax.get(CONSTRAINTS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(value => value.data).then(value => {
            setAllowedDevicePrefixes(value?.constraints.allowed_device_prefixes);
        }).catch(reason => {
            toast.error("Failed to fetch constraints.");
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const updateConstraints = (values) => {
        const token = localStorage.getItem('token');
        if (loading) return;
        setLoading(true);

        const payload = {
            "allowed_device_prefixes": values.allowedDevicePrefixes.split(',').map(prefix => prefix.trim())
        };
        ax.put(CONSTRAINTS_API_URL, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(value => value.data).then(value => {
            toast.success("Constraints updated.");
        }).catch(reason => {
            const error_msg = reason.response?.data?.error || "Failed to update constraints.";
            if (error_msg === "invalid_input") {
                toast.error("Invalid input. Please check the input.");
            }

        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='p-4-no-mobile' style={{ background: "#202020" }}>
            <Title level={2}>Update Sensor Constraints</Title>
            <Row>
                <Col sm={24} md={24} lg={12}>
                    {allowedDevicePrefixes.length > 0 && <Form
                        {...formLayout}
                        name="updateConstraints"
                        layout="vertical"
                        initialValues={{ allowedDevicePrefixes: allowedDevicePrefixes.join(', ') }}
                        onFinish={updateConstraints}>
                        <Form.Item
                            label="Allowed Device Prefixes"
                            name="allowedDevicePrefixes"
                            rules={[{ required: true, message: "Please enter prefixes separated by comma." }]}>
                            <Input.TextArea rows={4} />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Update Constraints
                            </Button>
                        </Form.Item>
                    </Form>}
                </Col>
            </Row>
        </div>
    )
};

export default UpdateSensorConstraints;