import React, { useEffect, useState } from 'react';
import ax from "axios";
import { toast } from "react-toastify";
import { Button, Table, Input, Space } from "antd";
import { USERS_API_URL } from "../../utils/constants";
import { Link } from 'react-router-dom';

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: "Created At",
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
    },
    {
        title: 'Devices',
        dataIndex: 'raspis',
        key: 'raspis',
        render: (raspis) => <ul>{raspis.map(each => <li><Link to={`/devices/${each}`}>{each}</Link></li>)}</ul >
    },
    {
        title: 'Actions',
        key: 'actions',
        render: (record) => {
            return (
                <Link to={`/users/${record._id}`}><Button type="primary">View</Button></Link>
            );
        }
    }
];

const FiltersAndButtons = ({ onSearch }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
            <Input.Search
                placeholder="Search by name or email"
                onSearch={onSearch}
                style={{ width: 300 }}
            />
            <Space>
                <Link to='/addUser'><Button style={{ marginLeft: 20 }} type="primary">Add User</Button></Link>
                <Button>Export</Button>
            </Space>
        </div>
    );
};

const Clients = () => {
    const [clients, setClients] = useState([]);
    const [queryParam, setQueryParam] = useState({});
    const [loading, setLoading] = useState();

    useEffect(() => {
        const token = localStorage.getItem('token');
        setLoading(true);
        ax.get(USERS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: queryParam
        })
            .then(value => value.data)
            .then(value => {
                setClients(value);
            })
            .catch(reason => {
                toast.error("Failed to fetch clients.");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [queryParam]);
    const handleSearch = (value) => {
        console.log("Search value:", value);
        setQueryParam({ search: value });
    };
    const handleTableChange = (pagination, filters, sorter) => {
        const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
        setQueryParam({
            ...queryParam,
            sort: { [sorter.field]: sortOrder, ...queryParam.sort }
        });
    };
    return (
        <>
            <FiltersAndButtons onSearch={handleSearch} />
            <Table
                dataSource={clients}
                loading={loading}
                columns={columns}
                rowKey="_id"
                onChange={handleTableChange}
            />
        </>
    );
};

export default Clients;
