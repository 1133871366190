import React from 'react';
import ReactDOM from 'react-dom';
import ClientApp from './ClientApp';
import AdminApp from './AdminApp';
import * as serviceWorker from './serviceWorker';
// import App from './App';


Storage.prototype.setObject = function (key, value) {
    this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
    let value = this.getItem(key);
    return value && JSON.parse(value);
};


const user = localStorage.getObject('user');
ReactDOM.render(user?.role === 'admin' ? <AdminApp /> : <ClientApp />, document.getElementById('root'));


serviceWorker.unregister();