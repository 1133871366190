import React, { useState } from 'react';
import ax from "axios";
import { Form, Input, Button } from 'antd';
import { USERS_API_URL } from '../../utils/constants';
import { toast } from "react-toastify";

const UpdateUser = ({ user: initialUser }) => {

    const [loading, setLoading] = useState(false);

    const handleUpdateUser = async (values) => {
        console.log('Data:', values);
        try {
            setLoading(true);
            const { data } = await ax.put(`${USERS_API_URL}/${initialUser.id}`, values, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
            });
            toast.success('User updated successfully');
        }
        catch (error) {
            console.error('Error updating user:', error);
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <Form
            onFinish={handleUpdateUser}
            initialValues={initialUser}
            style={{ margin: 20 }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
        >
            <Form.Item
                label="Name"
                name="name"
            >
                <Input />
            </Form.Item>
            {/* <Form.Item
                label="Email"
                name="email"
            >
                <Input type="email" />
            </Form.Item> */}
            <Form.Item
                label="Phone"
                name="phone"
                value="asdfasdfasdf"
            >
                <Input />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Update User
                </Button>
            </Form.Item>
        </Form>
    )
}

export default UpdateUser;