import React, { useState, useEffect } from "react";
import moment from "moment";
import ax from "axios";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Card, Col, Row, Statistic, Select, List, Typography } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import { AGGREGATE_ANALYTICS_API_URL, TIME_TRIGGERS_API_URL } from "../../utils/constants";
import { Link } from "react-router-dom";
import ActivityStaticsCard from "../../Components/admin/ActivityStatisticsCard";


const Dashboard = () => {
    const [analytics, setAnalytics] = useState({});
    const [timeTriggers, setTimeTriggers] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('token');
    useEffect(() => {
        ax.get(AGGREGATE_ANALYTICS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(value => value.data)
            .then(value => {
                setAnalytics(value);
            })
            .finally(() => setLoading(false))
    }, []);

    useEffect(() => {
        ax.get(TIME_TRIGGERS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(value => value.data)
            .then(value => {
                setTimeTriggers(value);
            })
            .finally(() => setLoading(false))
    }, []);

    const options = {
        chart: {
            type: 'pie',
            backgroundColor: 'transparent',
        },
        title: {
            text: analytics.sensorCount?.OFF + analytics.sensorCount?.ON + ' Sensors',
            style: {
                color: '#b9b9b9'
            }
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b>'
        },
        accessibility: {
            point: {}
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.y}'
                }
            }
        },
        series: [{
            name: 'Status',
            colorByPoint: true,
            data: [{
                name: 'ON',
                y: analytics.sensorCount?.ON,
                sliced: true,
                selected: true
            }, {
                name: 'OFF',
                y: analytics.sensorCount?.OFF
            }]
        }]
    };
    return (
        <Row gutter={16}>
            <Col xs={24} md={8}>
                <Card className="dashboard-card">
                    <Link to="/users">
                        <Statistic
                            title="Active Users"
                            value={analytics.userCount}
                            valueStyle={{ color: '#267ddc' }}
                            prefix={<ArrowUpOutlined />}
                        />
                    </Link>
                </Card>
            </Col>
            <Col xs={24} md={8}>
                <Card className="dashboard-card">
                    <Link to="/devices">
                        <Statistic
                            title="Active Devices"
                            value={analytics.deviceCount}
                            valueStyle={{ color: '#267ddc' }}
                            prefix={<ArrowUpOutlined />}
                        />
                    </Link>
                </Card>
            </Col>
            <Col xs={24} md={8}>
                <ActivityStaticsCard activityCount={analytics.activityCount} />
                {/* <Card className="dashboard-card">
                    <Link to="/activities">
                        <Statistic
                            title="Total Activities"
                            value={analytics.activityCount?.count}
                            valueStyle={{ color: '#267ddc' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="Last 24 hours"
                        />
                    </Link>
                </Card> */}
            </Col>
            <Col xs={24} md={12}>
                <Card title="Sensors" className="dashboard-card">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </Card>
            </Col>
            <Col xs={24} md={12}>
                <Card title={<><span>Alerts</span> <span style={{ paddingLeft: "5px", color: "gray", fontWeight: 200 }}>Last 30 days</span></>} className="dashboard-card">
                    <List
                        itemLayout="horizontal"
                        dataSource={timeTriggers}
                        renderItem={item => {
                            const now = moment.utc();
                            const itemTime = moment.utc(item.time);
                            const duration = moment.duration(now.diff(itemTime));

                            const days = duration.days();
                            const hours = duration.hours();
                            const minutes = duration.minutes();

                            let timeDiffString = '';
                            if (days > 0) {
                                timeDiffString += `${days} days `;
                            }
                            if (hours > 0) {
                                timeDiffString += `${hours} hours `;
                            }
                            if (minutes > 0) {
                                timeDiffString += `${minutes} minutes `;
                            }
                            timeDiffString += 'ago';

                            const statusStyle = {
                                color: item.status === 'ON' ? 'red' : 'gray',
                                marginLeft: 'auto'
                            };
                            return (
                                <Link to={`/devices/${item.raspi}`}>
                                    <List.Item style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography.Title level={4} style={{ flex: 1 }}>{item.knownName}</Typography.Title>
                                        <div style={{ ...statusStyle, flex: 1 }}>{item.status}</div>
                                        <div style={{ flex: 1 }}>{timeDiffString.trim()}</div>
                                    </List.Item>
                                </Link>
                            )
                        }}
                    />
                </Card>
            </Col>
        </Row>
    );
}

export default Dashboard;