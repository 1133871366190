import React, { Component } from 'react';
import { Route, BrowserRouter, Redirect } from "react-router-dom";
import { Layout } from 'antd';
import { toast, ToastContainer } from "react-toastify";

import Login from "./Pages/Login";
import ProtectedRoute from "./Components/ProtectedRoute";
import Devices from "./Pages/client/Devices";
import Device from "./Pages/client/Device";
import DeviceSettings from "./Pages/client/DeviceSettings";
import DeviceAdd from "./Pages/client/DeviceAdd";
import { SocketContext, socket } from './Components/client/SocketContext';
import Logout from "./Pages/Logout";
import MainHeader from "./Components/client/Header";
import Register from "./Pages/Register";

// import "./Styles/antd.dark.css";
import "antd/dist/antd.css";
import "./Styles/toastify.css";
import "./Styles/Style.scss";
// import * as firebase from 'firebase';

const { Content } = Layout;


export default class ClientApp extends Component {

    constructor(props) {
        super(props);
        this.state = { auth: false, token: "" };
    }


    componentDidMount() {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            import("antd/dist/antd.dark.css").then(() => {
                console.log("Dark theme CSS has been applied");
            });
        }
        console.log("App Initiated");
    }


    render() {
        const hasSecrets = !!localStorage.getItem("secret");
        return (<SocketContext.Provider value={socket}>
            <BrowserRouter>
                <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />

                <Layout className="layout">
                    {hasSecrets && <MainHeader />}
                    <Content>
                        <div className="site-layout-content">


                            <ProtectedRoute path="/addDevice" exact component={DeviceAdd} />
                            <ProtectedRoute path="/devices/:id/settings" exact component={DeviceSettings} />
                            <ProtectedRoute path="/devices/:id" exact component={Device} />
                            <ProtectedRoute path="/devices" exact component={Devices} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/register" component={Register} />
                            <ProtectedRoute exact path="/logout" component={Logout} />
                            <ProtectedRoute path="/" exact component={() => {
                                return <Redirect to={"/devices"} />
                            }
                            } />

                        </div>
                    </Content>
                </Layout>
            </BrowserRouter>
        </SocketContext.Provider>)

    }

}
